.user-form{
    /* .ant-select{
        .ant-select-selector{
            height: 48px;

            .ant-select-selection-search{
               input{
                    height: 48px;
                }
            }

            .ant-select-selection-item,.ant-select-selection-placeholder{
                line-height: 46px;
            }

            
        }
    } */
    .ant-select {
        .ant-select-selector {
          border-color: var(--border);
          height: unset;
          padding: 0.5rem 0.75rem;
        }
      }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.number-format {
	display: inline-flex;
	word-break: break-word;
}



.scroll-table { 
  overflow: auto;
   height: 600px;

    & thead th{ position: sticky; top: 0; z-index: 1; }
    & tbody th{ position: sticky; left: 0; }

    table  { border-collapse: collapse; width: 100%; }
}


.hr-table{
  border-width:4px;
}

.scrollbar-600{
  overflow-x: clip;
  overflow-y: auto;
  max-height: 600px;
}

.scrollbar{
  overflow-x: clip;
  overflow-y: auto;
}
.mh-450{
  max-height: 450px !important;
}

.scrollbar-300{
  overflow-x: clip;
  overflow-y: auto;
  max-height: 316px;
}

.chart {
  display: flex;
  flex-direction: column;
  height: 300px;

  .ant-divider {
    display: none;
  }

  @media (max-width: 991px) {
    min-height: 10rem;

    .ant-divider {
      display: flex;
    }
  }

  .recharts-tooltip-wrapper {
    padding: 0 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--color-neutral-text, #272829);
    background-color: rgba(255, 255, 255, 0.85);
    font-size: 0.75rem;
    font-family: 'Lucida Grande', 'Lucida Sans Unicode', Arial, Helvetica, sans-serif;

    .number-format {
      font-weight: 600;
    }
  }
}

div.ant-tooltip {
  max-width: 21.875rem;
  font-size: 0.75rem;

  @media (max-width: 574px) {
    max-width: 15.625rem;
  }

  &-inner {
    display: flex;
  }

  .tooltip-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.w-fit-content {
  width: fit-content !important;
}

.upload-blog{
  margin:0;
  min-height:150px;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn{
    font-size: 14px;
  }
}

.image-blog{
  max-width: 600px;
  max-height: 300px;
  object-fit: contain;
}

.calendar-wrapper {
  --rmdp-primary-purple: #733aea;
    --rmdp-secondary-purple: #733aea;
    --rmdp-shadow-purple: #733aea;
    --rmdp-today-purple: #733aea;
    --rmdp-hover-purple: white;
    --rmdp-deselect-purple: #733aea;
  div{
    width: 100%;
  }
  .rmdp-day{
    pointer-events: none;
    
    span{
      border-radius: unset;
    }

    span:hover {
      color:black !important;
    }
  }

  .rmdp-selected{
    span{
      border-radius: unset;
    }

  }

  .rmdp-arrow-container:hover{
    background-color:unset !important; 
    box-shadow: unset !important;
  }

  .rmdp-arrow-container:hover .rmdp-arrow {
    border: solid #733aea;
    border-width: 0 2px 2px 0;
}
}

.ant-select-multiple{

  .ant-select-selection-item{
    height: unset;
    padding: 2px 4px;
  }
}

.compare-select{
  .ant-select-selector{
    padding: 8px 22px 8px 4px;
  }
}

.bg-item ul li .star-normal{
  color: var(--white) !important;
}

.dot-icon{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: var(--green);
}

.hover-effect{
  &:hover{
    background-color: #F0F0F0;

  }

  transition: all 0.2s;
}
.row-attributes {
  border-top: 1px dashed var(--border);

  .list-attributes{
    padding: 8px 6px;
    border-bottom: 1px dashed var(--border);
    color: var(--title);
    font-family: var(--font);
    &:nth-child(n){
      background-color: transparent;
    }
    &:nth-child(2n){
      background-color: var(--light);
    }
    @media (min-width: 575px) {
      &:nth-child(n){
        background-color: transparent;
      
      }
      &:nth-child(4n+1), &:nth-child(4n+2){
        background-color: var(--light);
      }
    }
  
    @media (min-width: 768px) {
      &:nth-child(n){
        background-color: transparent;
      
      }
      &:nth-child(6n+1), &:nth-child(6n+2), &:nth-child(6n+3){
        background-color: var(--light);
      }
    }
  .item-attributes{
      .value{
        font-family: var(--font_b);
      }
    }
  }
}

.text-truncate-container {

  .truncate-2 {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .truncate-3 {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.w-32px{
  width: 32px !important;
}

.h-32px{
  height: 32px !important;
}

.w-50px{
  width: 50px !important;
}



.h-50px{
  height: 50px !important;
}

.text-title{
  font-size: 1rem;
  color: var(--title);
  font-family: var(--font);
}

.apply-item{
  
  border:1px solid var(--border);
  padding: 2px 6px;
  font-size: 11px;
  border-radius: 6px;

  &.done {
    color: var(--green);
    border:1px solid var(--greenBorder);
    background-color: var(--greenBg);
  }

  &.processing {
    color: var(--primary);
    border:1px solid var(--primaryBorder);
    background-color: var(--primaryBg);
  }

  &.pending {
    color: var(--gold);
    border:1px solid var(--goldBorder);
    background-color: var(--goldBg);
  }
}

.render-content-html{
  overflow: hidden;
  
  p {
    font-family:  'Roboto', sans-serif, Helvetica, Arial !important;
    font-weight: 400;
  }

  h1, h2, h3, h4, h5, h6 {
      line-height: 26px;
      font-weight: bold;
      font-family: 'Oswald', sans-serif, Helvetica, Arial !important;
  }

  h1{
    font-size: 32px;
    margin-top: 35px;
    line-height: 36px;
    clear: inline-start;
    margin-bottom: 10px;
    color: #000;
  }

  h2{
      font-size: 29px;
      margin-top: 35px;
      line-height: 36px;
      clear: inline-start;
      margin-bottom: 10px;
      color: #000;
  }

  h3{
      font-size: 26px;
      margin-top: 35px;
      line-height: 36px;
      clear: inline-start;
      margin-bottom: 10px;
      color: #000;
  }

  h4{
      font-size: 24px;
      margin-top: 35px;
      line-height: 36px;
      clear: inline-start;
      margin-bottom: 10px;
      color: #000;
  }

  h5{
      font-size: 22px;
      margin-top: 35px;
      line-height: 36px;
      clear: inline-start;
      margin-bottom: 10px;
      color: #000;
  }

  h6{
      font-size: 20px;
      margin-top: 35px;
      line-height: 36px;
      clear: inline-start;
      margin-bottom: 10px;
      color: #000;
  }

  p {
      padding: 10px 0;
      font-size: 18px;
      line-height: 32px;
      color: #111;
  }

  li {
      padding: 5px 0 5px 5px;
      font-size: 18px !important;
      line-height: 32px;
  }

  table {
    overflow-x: auto;
    margin: 35px 0px 10px 0px;
  }

   img {
    margin:0;
    width: 100%;
    border-radius: 5px;
  }

  .box-item-element {
    background: #fff;
    background: var(--white);
    border-radius: 10px;
    margin-bottom: 30px;
    padding: 40px 50px 50px 50px;
  }
}

.object-fit-contain{
  object-fit: contain !important;
}

.object-fit-cover{
  object-fit: cover !important;
}

.swiper {
  width: 100%;
  height: 100%;
  overflow: visible;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-prev, .swiper-button-next {
    --swiper-navigation-size: 1.5rem;
    color:white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: rgba(115, 58, 234, 0.07);
    border: 1px solid rgba(115, 58, 234, 0.067);
    -webkit-backdrop-filter: blur(.75rem);
    backdrop-filter: blur(.75rem);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    box-shadow: 0 0.25rem 1rem 0 rgba(0,0,0,.1);
  }

  .swiper-button-prev{
    left: -1.25rem;
  }

  .swiper-button-next{
    right: -1.25rem;
  }
}

.upload-scholaship-offer{

  .ant-upload-list-picture {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap:10px;

    &::before{
      display: none;
    }
  }

  .ant-upload-list-item{
    margin-top:0 !important;
  }
}

.upload-create-ticket{

  .ant-upload-list{
    width: 50%;
    margin:auto;
  }

}

.color-text{
  color: var(--text) !important;
}

.color-title{
  color: var(--title) !important;
}


