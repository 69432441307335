.cursor-pointer {
    cursor: pointer;
}

#signin .form-control {
    background: var(--white);
}


/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
} */

input[type='date']::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.footer-element {
    text-align: center;
    color: #733aea;
    cursor: pointer;
    text-decoration: underline;
}

.no-data img {
    width: 100px;
}

.list-video-item iframe {
    border-radius: 10px;
    width: 100%;
}

.list-video-item iframe,
.list-video-item iframe:hover {
    background: none !important;
    border: 0 !important;
}